import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: () => import("../views/LoginView.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters.getSession) {
        next();
      } else if (!store.getters.getAllConfigs) {
        router.push("/locality");
      } else {
        router.push("/board");
      }
    },
  },
  {
    path: "/locality",
    name: "Locality",
    component: () => import("../views/LocalityView.vue"),
    beforeEnter: (to, from, next) => {
      if (store.getters.getSession) {
        next();
      } else {
        router.push("/");
      }
    },
  },
  {
    path: "/bases",
    name: "BasesView",
    component: () => import("../views/SetFloorBasesView.vue"),
    beforeEnter: (to, from, next) => {
      if (store.getters.getSession) {
        next();
      } else {
        router.push("/");
      }
    },
  },
  {
    path: "/average",
    name: "AverageView",
    component: () => import("../views/SetAverageView.vue"),
    beforeEnter: (to, from, next) => {
      if (store.getters.getSession) {
        next();
      } else {
        router.push("/");
      }
    },
  },
  {
    path: "/map",
    name: "MapEditor",
    component: () => import("../views/MapEditorView.vue"),
    beforeEnter: (to, from, next) => {
      if (store.getters.getSession) {
        next();
      } else {
        router.push("/");
      }
    },
  },
  {
    path: "/board/:config?",
    name: "Maxboard",
    component: () => import("../views/BoardView.vue"),
    beforeEnter: (to, from, next) => {
      if (store.getters.getAllConfigs) {
        next();
      } else {
        router.push("/");
      }
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
