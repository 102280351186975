import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const messages = {
  en_us: {
    message: {
      login: {
        subTitle: "Occupancy information panel",
        card: {
          title: "Login",
          subTitle: "Enter your Skedway email",
          information: "Make sure it has the necessary permissions.",
          placeholder: "Email",
          alert: "Error Text",
        },
        company: {
          title: "Select the company you want to enter.",
        },
        password: {
          subTitle: "Enter your password to log into the company.",
          placeholder: "Password",
        },
        pwaText: "Install the PWA web app on your device.",
      },
      logout: {
        title: "Authentication",
        logout_subTitle: "Validate your email and password:",
        next_button: "Logout",
        config_button: "Reconfigure",
        back_button: "Back",
      },
      locality: {
        title: "Location monitoring",
        subTitle: "Workplace 3D will be configured to",
        search: "Search",
        site: "Site",
        building: "Building",
        floor: "Floor",
      },
      bases: {
        title: {
          row_01: "Choose two (2) asset bases for",
          row_02: "the current floor occupancy information.",
        },
      },
      averageBase: {
        title: {
          row_01: "Choose one (1) asset base for",
          row_02: "the site occupancy information.",
        },
      },
      mapConfig: {
        title: "Map orientation",
        subTitle: {
          row_01: "Adjust the map tilt to match the actual floor orientation.",
          row_02: "Keep the map within the demarcated area.",
        },
        caption: "Subtitle",
        actions: {
          move: "Move",
          rotation: "Rotate",
          zoom: "Zoom",
          reset: "Reset",
        },
      },
      board: {
        bases: {
          title: "Available today",
          free: "Available",
        },
        averageBase: {
          title: "Occupancy history",
        },
        activePeoples: {
          title: "People on this floor",
        },
        map: {
          caption: {
            free: "Available",
            busy: "Occupied",
            waiting: "Awaiting check-in",
          },
        },
        themes: {
          title: "Tema",
          theme_mode_title: "Skedway",
          custom_theme_title: "Personalized",
          save_button: "Save",
          alert_text: "Changing the color will affect all logged in devices.",
        },
      },
      cardsActions: {
        backButton: "Back",
        nextButton: "Next",
      },
      alerts: {
        email_required: "Email is required",
        email_invalid: "Invalid email",
        not_found: "Email not found",
        password_required: "Password is required",
      },
    },
  },
  pt_br: {
    message: {
      login: {
        subTitle: "Painel informativo de ocupação",
        card: {
          title: "Login",
          subTitle: "Insira seu email Skedway.",
          information:
            "Certifique-se que ele possua as permissões necessárias.",
          placeholder: "Email",
          alert: "Error Text",
        },
        company: {
          title: "Selecione a empresa desejada.",
        },
        password: {
          subTitle: "Digite sua senha para entrar.",
          placeholder: "Senha",
        },
        pwaText: "Instale o webapp PWA em seu dispositvo.",
      },
      logout: {
        title: "Autenticação",
        logout_subTitle: "Valide seu email e senha:",
        next_button: "Sair",
        config_button: "Reconfigurar",
        back_button: "Voltar",
      },
      locality: {
        title: "Localidade do monitoramento",
        subTitle: "Workplace 3D será configurado para:",
        search: "Pesquisar",
        site: "Site",
        building: "Edifício",
        floor: "Piso",
      },
      bases: {
        title: {
          row_01: "Escolha duas (2) bases de ativos para o",
          row_02: "informativo da ocupação do andar atual.",
        },
      },
      averageBase: {
        title: {
          row_01: "Escolha uma (1) das bases de ativos para o",
          row_02: "informativo da ocupação do site.",
        },
      },
      mapConfig: {
        title: "Orientação do mapa",
        subTitle: {
          row_01:
            "Ajuste a posição e a inclinação do mapa para ficar de acordo com a orientação real do andar.",
          row_02: "Mantenha o mapa dentro da área demarcada.",
        },
        caption: "Legenda",
        actions: {
          move: "Mover",
          rotation: "Rotacionar",
          zoom: "Zoom",
          reset: "Resetar",
        },
      },
      board: {
        bases: {
          title: "Disponível hoje",
          free: "Disponíveis",
        },
        averageBase: {
          title: "Histórico de ocupação",
        },
        activePeoples: {
          title: "Pessoas neste andar",
        },
        map: {
          caption: {
            free: "Disponível",
            busy: "Ocupado",
            waiting: "Aguardando check-in",
          },
        },
        themes: {
          title: "Tema",
          theme_mode_title: "Skedway",
          custom_theme_title: "Personalizado",
          save_button: "Salvar",
          alert_text:
            "A alteração da cor afetará todos os dispositivos logados.",
        },
      },
      cardsActions: {
        backButton: "Voltar",
        nextButton: "Próximo",
      },
      alerts: {
        email_required: "Email é obrigatório",
        email_invalid: "Email inválido",
        not_found: "Email não encontrado",
        password_required: "Senha é obrigatória",
      },
    },
  },
  es_es: {
    message: {
      login: {
        subTitle: "Panel informativo de ocupación",
        card: {
          title: "Inicio de sesión",
          subTitle: "Ingrese su correo electrónico Skedway.",
          information: "Asegúrese de que tenga los permisos necesarios.",
          placeholder: "Correo Electrónico",
          alert: "Error Texto",
        },
        company: {
          title: "Seleccione la empresa deseada.",
        },
        password: {
          subTitle: "Ingrese su contraseña para ingresar.",
          placeholder: "Contraseña",
        },
        pwaText: "Instale la aplicación web PWA en su dispositivo.",
      },
      logout: {
        title: "Autenticación",
        logout_subTitle: "Valida tu correo electrónico y contraseña:",
        next_button: "Salir",
        config_button: "Reconfigurar",
        back_button: "Volver",
      },
      locality: {
        title: "Ubicación de monitoreo",
        subTitle: "Workplace 3D se configurará para",
        search: "Buscar",
        site: "Sitio",
        building: "Edificio",
        floor: "Piso",
      },
      bases: {
        title: {
          row_01: "Elija dos (2) bases de activos para la",
          row_02: "información de ocupación actual del piso.",
        },
      },
      averageBase: {
        title: {
          row_01: "Elija una (1) de las bases de activos para la",
          row_02: "información de ocupación del sitio.",
        },
      },
      mapConfig: {
        title: "Orientación del mapa",
        subTitle: {
          row_01:
            "Ajuste la posición e inclinación del mapa para que coincida con la orientación real del piso.",
          row_02: "Mantenga el mapa dentro del área demarcada.",
        },
        caption: "Subtitular",
        actions: {
          move: "Mover",
          rotation: "Rotar",
          zoom: "Zoom",
          reset: "Restablecer",
        },
      },
      board: {
        bases: {
          title: "Disponible hoy",
          free: "Disponible",
        },
        averageBase: {
          title: "Historial de ocupación",
        },
        activePeoples: {
          title: "Personas en este piso",
        },
        map: {
          caption: {
            free: "Disponible",
            busy: "Ocupado",
            waiting: "Esperando check-in",
          },
        },
        themes: {
          title: "Tema",
          theme_mode_title: "Skedway",
          custom_theme_title: "Personalizado",
          save_button: "Ahorrar",
          alert_text:
            "Cambiar el color afectará a todos los dispositivos conectados.",
        },
      },
      cardsActions: {
        backButton: "Volver",
        nextButton: "Siguiente",
      },
      alerts: {
        email_required: "Correo electrónico requerido",
        email_invalid: "Correo electrónico no válido",
        not_found: "Correo electrónico no encontrado",
        password_required: "La contraseña es obligatoria",
      },
    },
  },
};

const i18n = new VueI18n({
  locale: "en_us",
  fallbackLocale: "en_us",
  messages,
});

export default i18n;
