<template>
  <v-row no-gutters justify="center" align="center">
    <v-col cols="auto">
      <div
        ref="iconRef"
        class="svgDiv"
        :style="{ width: width, height: height }"
        v-if="selectedIconContent"
        v-html="selectedIconContent"
      ></div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    selectedIcon: {
      type: String,
      required: true,
    },
    fill: {
      type: String,
      default: "#000000", // ou qualquer valor padrão desejado
    },
    width: {
      type: String,
      default: "24",
    },
    height: {
      type: String,
      default: "24",
    },
  },
  data() {
    return {
      iconWidth: "24",
      iconHeight: "24",
      selectedIconContent: "",
    };
  },
  created() {
    this.loadIcon();
  },
  methods: {
    loadIcon() {
      const isURL = /^https?:\/\//.test(this.selectedIcon);
      let svgPath = null;
      if (isURL) {
        svgPath = this.selectedIcon;
      } else {
        svgPath = `/assets/svg_icons/${this.selectedIcon}.svg`;
      }

      fetch(svgPath)
        .then((response) => response.text())
        .then((svgContent) => {
          this.parseSVGContent(svgContent);
        })
        .catch((error) => {
          console.error(`Erro ao carregar o SVG: ${error}`);
        });
    },
    parseSVGContent(svgContent) {
      // Parse do conteúdo do SVG para extrair largura, altura e viewBox
      const parser = new DOMParser();
      const doc = parser.parseFromString(svgContent, "image/svg+xml");
      const svgElement = doc.documentElement;

      svgElement.setAttribute("width", this.width);
      svgElement.setAttribute("height", this.height);
      svgElement.setAttribute("fill", this.fill);

      const paths = svgElement.querySelectorAll("path");
      for (const path of paths) {
        path.setAttribute("fill", this.fill);
      }

      const newSVG = new XMLSerializer().serializeToString(svgElement);
      // Atualiza o conteúdo do SVG
      this.selectedIconContent = newSVG;
    },
    updateFill() {
      const svg = this.$refs.iconRef;
      if (svg) {
        const paths = svg.querySelectorAll("path");
        for (const path of paths) {
          path.setAttribute("fill", this.fill);
        }
      }
    },
  },
  watch: {
    selectedIcon: "loadIcon",
    fill: "updateFill",
  },
};
</script>
<style scoped>
.svgDiv {
  display: flex;
}
</style>
