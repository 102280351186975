<template>
  <v-btn
    class="nextButton"
    color="#5a6c72"
    @click="$emit('click')"
    :disabled="disabled"
    elevation="0"
    :loading="isLoading"
  >
    {{
      logout
        ? $t("message.logout.next_button")
        : $t("message.cardsActions.nextButton")
    }}
    <template v-slot:loader>
      <span class="loader"> </span>
    </template>
  </v-btn>
</template>
<script>
export default {
  name: "NextButton",
  props: {
    disabled: { type: Boolean, default: false },
    logout: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
  },
  computed: {
    isLandscape() {
      return this.$vuetify.breakpoint.height < this.$vuetify.breakpoint.width;
    },
  },
};
</script>
<style scoped>
@media (orientation: landscape) {
  .nextButton {
    display: flex;
    color: white;
    border-radius: 0.6vw;
    font-family: "Manrope", sans serif !important;
    font-weight: 600;
    font-size: 0.8vw;
    letter-spacing: 0vw;
    text-transform: capitalize;
    padding: 1.1vw !important;
    background-color: #5a6c72ee !important;
    min-width: 4.5vw !important;
    height: 1vw !important;
    position: relative;
    z-index: 2;
  }
  .nextButton:active {
    background-color: #1e2b2d !important;
    color: white !important;
  }
  .nextButton:hover {
    color: #d8e6e4;
  }
  .loader {
    width: 1vw;
    height: 1vw;
    border: 0.15vw solid #fff;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
}

@media (orientation: portrait) {
  .nextButton {
    display: flex;
    color: white;
    border-radius: 0.7vh;
    font-family: "Manrope", sans serif !important;
    font-weight: 600;
    font-size: 0.8vh;
    letter-spacing: 0vh;
    text-transform: capitalize;
    padding: 1.1vh !important;
    background-color: #5a6c72ee !important;
    height: 1vh !important;
    min-width: 4.5vh !important;
    position: relative;
    z-index: 2;
  }
  .nextButton:active {
    background-color: #1e2b2d !important;
    color: white !important;
  }
  .nextButton:hover {
    color: #d8e6e4;
  }
  .loader {
    width: 1vh;
    height: 1vh;
    border: 0.15vh solid #fff;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
