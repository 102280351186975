const state = {
  current_theme: {
    mode: "dark",
    theme_id: 1,
  },
  themes: [
    {
      id: 1,
      name: "Default",
      dark: {
        primary_color: "#425B58",
        secondary_color: "#274743",
        tertiary_color: "#1E2B2D",
      },
      light: {
        primary_color: "#EDE8FB",
        secondary_color: "#FFFFFFCC",
        tertiary_color: "#FFFFFF",
      },
    },
  ],
  test_theme: null,
};

const getters = {
  getCurrentTheme(state) {
    if (state.current_theme.mode === "dark") {
      return state.themes.find((t) => t.id === state.current_theme.theme_id)
        .dark;
    } else {
      return state.themes.find((t) => t.id === state.current_theme.theme_id)
        .light;
    }
  },
  getCurrentThemeConfig(state) {
    return state.current_theme;
  },
  getAllThemes(state) {
    return state.themes;
  },
  isDarkTheme(state) {
    return state.current_theme.mode === "dark";
  },
  isTestingTheme(state) {
    if (!state.test_theme) return false;
    return state.test_theme;
  },
};

const mutations = {
  setCurrentTheme(state, payload) {
    state.current_theme = payload;
  },
  addNewCustomTheme(state, payload) {
    state.themes.push(payload);
  },
  setTestTheme(state, payload) {
    state.test_theme = payload;
  },
};

const actions = {
  addTheme({ commit, getters }, payload) {
    const newTheme = {
      id: getters.getAllThemes.length + 1,
      name: `Custom 0${getters.getAllThemes.length + 1}`,
      dark: payload.dark,
      light: payload.light,
    };
    commit("addNewCustomTheme", newTheme);
  },
  applyTestColor({ state, getters }) {
    state.themes.find((theme) => {
      if (theme.id === getters.getCurrentThemeConfig.theme_id) {
        if (getters.getCurrentThemeConfig.mode === "dark") {
          theme.dark.primary_color = getters.isTestingTheme.primary_color;
          theme.dark.secondary_color = getters.isTestingTheme.secondary_color;
          theme.dark.tertiary_color = getters.isTestingTheme.tertiary_color;
        } else {
          theme.light.primary_color = getters.isTestingTheme.primary_color;
          theme.light.secondary_color = getters.isTestingTheme.secondary_color;
          theme.light.tertiary_color = getters.isTestingTheme.tertiary_color;
        }
      }
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
