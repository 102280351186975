<template>
  <v-app>
    <v-btn
      v-if="$route.name !== 'Login' && $route.name !== 'Maxboard'"
      fab
      color="black"
      class="logoutButton"
      @click="logout()"
    >
      <v-icon color="white" :size="isLandscape ? '1vw' : '1vh'"
        >bi bi-box-arrow-right</v-icon
      >
    </v-btn>
    <v-main>
      <router-view v-slot="{ Component }">
        <v-slide-x-transition leave-absolute>
          <component :is="Component" />
        </v-slide-x-transition>
      </router-view>
    </v-main>
    <v-footer
      v-if="$route.name !== 'Maxboard'"
      fixed
      height="auto"
      class="footer"
    >
      <v-row no-gutters class="footer">
        <SVGLoader
          selected-icon="skedway_black"
          :height="isLandscape ? '0.8vw' : '0.8vh'"
          :width="isLandscape ? '7vw' : '7vh'"
        ></SVGLoader>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import SVGLoader from "@/components/loaders/SVGLoader.vue";
export default {
  name: "App",
  components: {
    SVGLoader,
  },
  data: () => ({}),
  methods: {
    logout() {
      this.$store.dispatch("logoutSession");
      this.$router.push("/");
    },
  },
  computed: {
    isLandscape() {
      return this.$vuetify.breakpoint.height < this.$vuetify.breakpoint.width;
    },
  },
};
</script>
<style scoped>
@media (orientation: landscape) {
  .footer {
    background-color: transparent;
    padding: 0px;
    margin-bottom: 0.5vw;
  }
  .logoutButton {
    position: absolute;
    top: 1%;
    right: 0%;
    z-index: 2;
    height: 2.5vw;
    width: 2.5vw;
  }
}
@media (orientation: portrait) {
  .footer {
    background-color: transparent;
    padding: 0px;
    margin-bottom: 0.5vh;
  }
  .logoutButton {
    position: absolute;
    top: 1%;
    right: 0%;
    margin-left: 0vh;
    z-index: 2;
    height: 2.5vh;
    width: 2.5vh;
  }
}
</style>
<style>
:root {
  font-family: "Manrope", sans serif !important;
}
#app {
  font-family: "Manrope", sans serif !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
}

@media (orientation: landscape) {
  .v-dialog {
    border-radius: 1.5vw !important;
  }
}
@media (orientation: portrait) {
  .v-dialog {
    border-radius: 1.5vh !important;
  }
}
</style>
