import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "bootstrap-icons/font/bootstrap-icons.min.css";
import "@fortawesome/fontawesome-free/css/all.css";
import i18n from "@/components/dictionary/dictionary";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

// Set the title of the app
document.title = "Maxboard";
// Set the icon of the app
const link = document.createElement("link");
link.rel = "icon";
link.href = "./assets/icons/skedway.ico";
document.getElementsByTagName("head")[0].appendChild(link);
