<template>
  <v-btn
    class="backButton"
    color="#5a6c72"
    @click="$emit('click')"
    elevation="0"
    outlined
    :disabled="disabled"
  >
    {{ $t("message.cardsActions.backButton") }}
  </v-btn>
</template>
<script>
export default {
  name: "BackButton",
  props: { disabled: { type: Boolean, default: false } },
};
</script>
<style scoped>
@media (orientation: landscape) {
  .backButton {
    display: flex;
    color: #5a6c72ee !important;
    border-radius: 0.6vw;
    font-family: "Manrope", sans serif !important;
    font-weight: 600;
    font-size: 0.8vw;
    letter-spacing: 0vh;
    padding: 1vw !important;
    text-transform: capitalize;
    min-width: 1vw !important;
    max-height: 1.5vw;
    border: 0.1vw solid #5a6c72;
    position: relative;
    z-index: 2;
  }
}

@media (orientation: portrait) {
  .backButton {
    display: flex;
    color: #5a6c72ee !important;
    border-radius: 0.7vh;
    font-family: "Manrope", sans serif !important;
    font-weight: 600;
    font-size: 0.8vh;
    letter-spacing: 0vh;
    padding: 1vh !important;
    text-transform: capitalize;
    height: 1vh !important;
    min-width: 1vw !important;
    border: 0.1vw solid #5a6c72;
    position: relative;
    z-index: 2;
  }
}
</style>
