const state = {
  selected_language: "en_us",
  selected_company: null,
  selected_locality: null,
  selected_bases: null,
  selected_average_base: null,
  all_bases_options_length: null,
};
const getters = {
  getSelectedLanguage(state) {
    return state.selected_language;
  },
  getCompanys(state) {
    return state.selected_company;
  },
  getLocality(state) {
    return state.selected_locality;
  },
  getBases(state) {
    return state.selected_bases;
  },
  getAverageBase(state) {
    return state.selected_average_base;
  },
  getAllBasesOptionsLength(state) {
    return state.all_bases_options_length;
  },
  getMap(state) {
    return state.selected_locality.floor;
  },
};
const mutations = {
  setSelectedLanguage(state, payload) {
    if (payload) {
      state.selected_language = payload;
    } else {
      state.selected_language = "en_us";
    }
  },
  setCompany(state, payload) {
    if (payload) state.selected_company = payload;
    else state.selected_company = null;
  },
  setLocality(state, payload) {
    if (payload) state.selected_locality = payload;
    else state.selected_locality = null;
  },
  setBases(state, payload) {
    if (payload) state.selected_bases = payload;
    else state.selected_bases = null;
  },
  setAverageBase(state, payload) {
    if (payload) state.selected_average_base = payload;
    else state.selected_average_base = null;
  },
  setAllBasesOptionsLength(state, payload) {
    state.all_bases_options_length = payload;
  },
};
const actions = {};

export default {
  state,
  getters,
  mutations,
  actions,
};
